import PartnersInterface, {
	Features,
	PartnerFeaturesInterface,
	PartnerIntegrationsInterface,
	PartnerSettingsInterface,
	PartnerSettingTypesIds
} from "../interfaces/PartnersInterface";
import { apiWrapper } from "../helpers/ApiWrapper";
import { PartnerSponsoredTypeModel } from "./SponsoredTypeModel";
import EntitiesInterface from "interfaces/EntitiesInterface";

export enum IntegrationType {
	Kindful = "kindful",
	Stripe = "stripe",
	DonorPerfect = "2"
}

export enum ApplicationFeeModel {
	Fee = "fee",
	Tip = "tip"
}

export default class PartnerModel {
	state: PartnersInterface;

	constructor(id: number | PartnersInterface) {
		if (typeof id === "object") {
			this.state = id;
		} else {
			typeof id == "number"
				? PartnerModel.getObjectById(id).then(results => (this.state = results))
				: PartnerModel.getObjectBySubdomain(id).then(results => {
						this.state = results as PartnersInterface;
				  });
		}
	}

	static async getObjectById(id: number): Promise<object> {
		let result = await apiWrapper(`partners/${id}`, { method: "get", authorized: false });
		return result;
	}

	static async getObjectBySubdomain(subdomain: string): Promise<object> {
		let result = await apiWrapper(`partners/${subdomain}`, { method: "get", authorized: false });
		return result;
	}

	static async fetchPartners(filters: object): Promise<any> {
		return new Promise(resolve => {
			let filterString: string = "";
			for (const index in filters) {
				filterString += "&" + index + "=" + filters[index];
			}
			apiWrapper("partners?" + filterString, { method: "get", authorized: false }).then(data => resolve(data));
		});
	}

	getState(): object {
		return this.state;
	}

	statePropertyOrError(stateProperty: string): any {
		if (this.state[stateProperty] !== "undefined") return this.state[stateProperty];
		throw new Error(`Error - Invalid partner model state property ${stateProperty}`);
	}

	isReady(): boolean {
		return this.state.id ? true : false;
	}

	getId(): number {
		return this.statePropertyOrError("id");
	}

	getName(): string {
		return this.statePropertyOrError("name");
	}

	get facebook(): string {
		return this.statePropertyOrError("facebook");
	}
	get instagram(): string {
		return this.statePropertyOrError("instagram");
	}
	get twitter(): string {
		return this.statePropertyOrError("twitter");
	}
	get website(): string {
		return this.statePropertyOrError("website");
	}
	get sponsorProcFee(): string {
		return this.statePropertyOrError("sponsorProcFee");
	}
	get generalProcFee(): string {
		return this.statePropertyOrError("generalProcFee");
	}
	get currency(): string {
		return this.statePropertyOrError("currency");
	}
	get id(): number {
		return this.statePropertyOrError("id");
	}
	get countriesId(): number {
		return this.statePropertyOrError("countriesId");
	}
	get name(): string {
		return this.statePropertyOrError("name");
	}
	get ein(): string {
		return this.statePropertyOrError("ein");
	}
	get tagline(): string {
		return this.statePropertyOrError("tagline");
	}
	get description(): string {
		return this.statePropertyOrError("description");
	}
	get supportEmail(): string {
		return this.statePropertyOrError("supportEmail");
	}
	get imageUrl(): string {
		return this.statePropertyOrError("imageUrl");
	}
	get demoMode(): boolean {
		return this.statePropertyOrError("demoMode");
	}
	get privateDemo(): boolean {
		return this.statePropertyOrError("privateDemo");
	}
	get stripeUserId(): string {
		return this.statePropertyOrError("stripeUserId");
	}
	get stripeClientId(): string {
		return this.statePropertyOrError("stripeClientId");
	}
	get auth0ClientId(): string {
		return this.statePropertyOrError("auth0ClientId");
	}
	get principalContactName(): string {
		return this.statePropertyOrError("principalContactName");
	}
	get principalContactEmail(): string {
		return this.statePropertyOrError("principalContactEmail");
	}
	get principalContactTitle(): string {
		return this.statePropertyOrError("principalContactTitle");
	}
	get accountManagerName(): string {
		return this.statePropertyOrError("accountManagerName");
	}
	get accountManagerEmail(): string {
		return this.statePropertyOrError("accountManagerEmail");
	}
	get partnerDomains(): object[] {
		return this.statePropertyOrError("partnerDomains");
	}
	get landingPageTemplatesId(): number {
		return this.statePropertyOrError("landingPageTemplatesId");
	}
	get landingPageFocus(): 1|2|undefined {
		return this.state.landingPageFocus;
	}
	get headerImage(): string {
		return this.statePropertyOrError("headerImage");
	}
	get donationFormImage(): string | undefined {
		return this.state.donationFormImage;
	}
	get logo(): string {
		return this.statePropertyOrError("logo");
	}
	get productsId(): string {
		return this.statePropertyOrError("productsId");
	}
	get partnerIntegrations(): PartnerIntegrationsInterface[] {
		return this.statePropertyOrError("partnerIntegrations");
	}
	get partnerFeatures(): PartnerFeaturesInterface[] | undefined {
		return this.state.partnerFeatures;
	}
	get partnerSettings(): PartnerSettingsInterface[] {
		return this.state.partnerSettings ?? [];
	}
	get maintenanceMode(): boolean {
		return !!this.state.maintenanceMode;
	}
	get active(): boolean {
		return !!this.state.active;
	}
	get isSupportSite(): boolean {
		return !!this.state.isSupportSite;
	}
	get isBillingConnected(): boolean {
		return !!this.state.isBillingConnected;
	}
	get applicationFeeModel(): ApplicationFeeModel {
		return this.state.applicationFeeModel?.toLowerCase() === "fee"
			? ApplicationFeeModel.Fee
			: ApplicationFeeModel.Tip ?? ApplicationFeeModel.Fee;
	}

	get usesTipModel(): boolean {
		return this.applicationFeeModel === ApplicationFeeModel.Tip;
	}

	get faithBased(): boolean {
		return !!this.state.faithBased;
	}

	get tipConfiguration(): number {
		return this.state.tipConfiguration ?? 1;
	}

	isConfigured(): boolean {
		return this.stripeUserId ? true : false;
	}

	getEntityIds(): Array<number> {
		const entityIds: Array<number> = [];
		if (this.state.entities) this.state.entities.forEach(entity => entityIds.push(entity.id));
		return entityIds;
	}

	getEntities(): Array<EntitiesInterface> {
		if (this.state.entities) return this.state.entities;
		return [];
	}

	getEntityById(entityId: number): EntitiesInterface {
		if (this.state.entities) {
			const entity: EntitiesInterface | undefined = this.state.entities.find(entity => entity.id == entityId);
			if (entity) return entity;
		}
		throw new Error(`Entity ID ${entityId} does not exist for current context partner`);
	}

	isAprann(): boolean {
		return this.state.id === 1;
	}

	isDemo(): boolean {
		return this.state.id === 2;
	}

	getPartnerSponsoredTypes(): Array<PartnerSponsoredTypeModel> {
		if (this.state.partnerSponsoredTypes) return this.state.partnerSponsoredTypes.map(type => new PartnerSponsoredTypeModel(type));
		return [];
	}

	getPartnerSponsoredTypeIds(): Array<number> {
		if (this.state.partnerSponsoredTypes) return this.state.partnerSponsoredTypes.map(type => type.id ?? 0);
		return [];
	}

	getPartnerSponsoredType(id: number | string): PartnerSponsoredTypeModel {
		if (typeof id === "string") {
			id = id.toLowerCase();
		}
		const types = this.statePropertyOrError("partnerSponsoredTypes");
		const type = types.find(type => type.id === id || type.identifier.toLowerCase() === id);
		return new PartnerSponsoredTypeModel(type);
	}

	hasIntegrationType(type: IntegrationType): boolean {
		return this.state.partnerIntegrations !== undefined && this.state.partnerIntegrations.some(e => e.integrationType === type);
	}

	getIntegrationType(type: IntegrationType): PartnerIntegrationsInterface | undefined {
		return this.state.partnerIntegrations?.find(e => e.integrationType === type);
	}

	//#region Partner Settings
	public hasCapability(featureId: Features): boolean {
		const test = this.state.partnerFeatures?.some(ps => ps.featuresId === featureId);
		return !!test;
	}

	public getPartnerSetting(partnerSettingTypesId: PartnerSettingTypesIds): string | undefined {
		return this.state.partnerSettings?.find(ps => ps.partnerSettingTypesId === partnerSettingTypesId)?.value?.toLowerCase();
	}

	public getPartnerSettingBool(partnerSettingTypesId: PartnerSettingTypesIds): boolean {
		const val: string = this.getPartnerSetting(partnerSettingTypesId) ?? "";
		return val.toLowerCase() === "true";
	}
	//#endregion
}
