import React, { Suspense, useEffect, useState } from "react";
import { Route, Switch, BrowserRouter as Router, Redirect } from "react-router-dom";
import { Auth0Provider } from "./services/Auth0/react-auth0-wrapper";
import { AprannAuthProvider } from "./helpers/AprannUser";

//context partner global state stuff
import { setPartner } from "./actions/PartnerActions";
import PartnerModel from "./models/PartnerModel";
import { useDispatch, useSelector } from "react-redux";

import UserSetup from "components/User/UserSetup";

import { LoadingMessage } from "./components/SplashScreen/SplashScreen";
import { configureApi } from "helpers/ApiWrapper";

import { ErrorBoundary } from "@sentry/react";
import { ErrorBoundaryFallback } from "helpers/ErrorBoundary";

import "./App.css";

const Dashboard = React.lazy(() => import("components/Admin/Dashboard/Dashboard"));
const Site = React.lazy(() => import("components/Site/Site"));
const SignupController = React.lazy(() => import("components/Signup/SignupController"));
const MaintenanceMode = React.lazy(() => import("components/Landing/MaintenanceLanding"));
const WidgetController = React.lazy(() => import("components/Site/Widgets/WidgetsController"));
const NavBar = React.lazy(() => import("components/NavBar/NavBar"));

const App = ({ domain }) => {
	const dispatch = useDispatch();
	const contextPartner = useSelector(state => state.PartnerReducer.contextPartner);

	const [redirect, setRedirect] = useState();

	useEffect(() => {
		PartnerModel.getObjectBySubdomain(domain)
			.then(partnerObj => {
				configureApi(partnerObj.id, partnerObj.auth0ClientId);
				dispatch(setPartner(new PartnerModel(partnerObj)));
			})
			.catch(error => {
				console.log(`Error setting context partner for ${domain} - ${error}`);

				// if (process.env.REACT_APP_BUILD_TYPE == "production") {
				// 	alert("Could not load Aprann site, redirecting...");
				// 	window.location = "https://www.aprann.org";
				// }
			});
	}, []);

	/**
	 * Redirect callback from auth 0. If redirect_uri was set properly, the path should be "/redirect", and appState.targetUrl
	 * may contain an option target url. A react router redirect is triggered with the setRedirect hook (see <Route path="/redirect"> below)
	 * @param object appState - contains optional parameter targetUrl
	 */
	const onRedirectCallback = appState => {
		setRedirect(appState?.targetUrl);
		window.history.replaceState({}, document.title, appState && appState.targetUrl ? appState.targetUrl : window.location.pathname);
	};

	if (!contextPartner.isReady()) return <LoadingMessage />;
	else if (contextPartner.maintenanceMode)
		return (
			<Suspense fallback={<LoadingMessage />}>
				<MaintenanceMode />
			</Suspense>
		);

	return (
		<Auth0Provider
			domain={process.env.REACT_APP_AUTH0_DOMAIN}
			client_id={contextPartner.auth0ClientId}
			redirect_uri={window.location.origin}
			onRedirectCallback={onRedirectCallback}
			audience={process.env.REACT_APP_AUTH0_AUDIENCE}
			useRefreshTokens={true}
			cacheLocation="localstorage"
		>
			<AprannAuthProvider>
				<ErrorBoundary fallback={ErrorBoundaryFallback} showDialog={true}>
					<Router>
						<Switch>
							{redirect && (
								<Route path="/redirect">
									<Redirect to={redirect} />
								</Route>
							)}
							<Route
								path="/dashboard"
								render={props => (
									<Suspense fallback={<LoadingMessage />}>
										<Dashboard contextPartner={contextPartner} />
									</Suspense>
								)}
							/>
							<Route
								path="/signup"
								render={props => (
									<Suspense fallback={<LoadingMessage />}>
										<NavBar />
										<SignupController />
									</Suspense>
								)}
							/>
							<Route
								path="/widget"
								render={props => (
									<Suspense fallback={<LoadingMessage />}>
										<WidgetController {...props} contextPartner={contextPartner} />
									</Suspense>
								)}
							/>
							<Route
								path="/"
								render={props => (
									<Suspense fallback={<LoadingMessage />}>
										<NavBar />
										<Site {...props} />
									</Suspense>
								)}
							/>
						</Switch>
						<UserSetup contextPartner={contextPartner} />
					</Router>
				</ErrorBoundary>
			</AprannAuthProvider>
		</Auth0Provider>
	);
};

export default App;
