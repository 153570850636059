import { PartnerSponsoredTypesInterface, CustomColumnsInterface, SponsoredTypeIds } from "interfaces/SponsoredTypesInterface";
import FilterTypeModel from "./FilterTypesModel";
import PartnerSponsoredTypePlansInterface from "interfaces/PartnerSponsoredTypePlansInterface";
import { PaymentIntervalsInterface } from "interfaces/PaymentsInterface";

import { formatDbDate } from "helpers";
import PartnerSponsoredTypePlanIntervalsInterface from "interfaces/PartnerSponsoredTypePlanIntervalsInterface";
import { IAprannTableColumn } from "components/UIComponents/AprannTable/AprannTable";
import SponsoredsInterface from "interfaces/SponsoredsInterface";

const indexColumns: IAprannTableColumn<SponsoredsInterface>[][] = [];
indexColumns[SponsoredTypeIds.Student] = [
	{
		field: "firstName",
		title: "First Name"
	},
	{
		field: "lastName",
		title: "Last Name"
	},
	{
		field: "grades.description",
		title: "Grade",
		render: rowData => (rowData.grades?.description ?? ""),
		customSort: (a, b) => { return a.grades?.description?.localeCompare(b.grades?.description); }
	},
	{
		field: "homeroom",
		title: "Homeroom",
		canToggle: true,
		isHidden: true
	},
	{
		field: "age",
		title: "Age",
		filtering: false
	},
	{
		field: "birthday",
		title: "Birthday",
		render: rowData => formatDbDate(rowData.birthday),
		filtering: false
	}
];

indexColumns[SponsoredTypeIds.Teacher] = [
	{
		field: "firstName",
		title: "First Name"
	},
	{
		field: "lastName",
		title: "Last Name"
	},
	{
		title: "Grade",
		render: rowData => rowData["grades"]["description"]
	}
];

indexColumns[SponsoredTypeIds.Employee] = [
	{
		field: "firstName",
		title: "First Name"
	},
	{
		field: "lastName",
		title: "Last Name"
	},
	{
		field: "title",
		title: "Title"
	}
];

indexColumns[SponsoredTypeIds.Classroom] = [
	{
		field: "title",
		title: "Class Title"
	},
	{
		field: "grades.description",
		title: "Grade"
	}
];

indexColumns[SponsoredTypeIds.People] = [
	{
		field: "title",
		title: "Profile"
	}
];

indexColumns[SponsoredTypeIds.Animal] = [
	{
		field: "firstName",
		title: "First Name"
	},
	{
		field: "lastName",
		title: "Last Name"
	},
	{
		field: "title",
		title: "Species/Breed"
	},
	{
		field: "age",
		title: "Age",
		filtering: false
	},
	{
		field: "birthday",
		title: "Birthday",
		render: rowData => formatDbDate(rowData.birthday),
		filtering: false
	}
];

export class PartnerSponsoredTypeModel {
	state: PartnerSponsoredTypesInterface;
	constructor(state: PartnerSponsoredTypesInterface) {
		this.state = state;
	}

	get id() {
		return this.state.id;
	}

	get partnersId() {
		return this.state.partnersId;
	}

	get currentSponsoredPlansId() {
		return this.state.currentSponsoredTypePlans?.id;
	}

	get filterTypes() {
		return this.state.sponsoredTypes?.sponsoredTypeFilterTypes.map(filter => new FilterTypeModel(filter.filterTypes)) ?? [];
	}

	//deprecated, this should just return boolean visibleSlots > 1
	get showSlots(): boolean {
		return this.state.showSlots;
	}
	get visibleSlots(): number {
		return this.state.visibleSlots;
	}
	get maxSlots(): number {
		return this.state.maxSlots;
	}
	get customSlotName(): string | undefined {
		return this.state.customSlotName;
	}
	get description(): string {
		return this.state.description;
	}
	get customLabelSingular(): string {
		return this.state.customLabelSingular?.toLowerCase();
	}
	get customLabelPlural(): string {
		return this.state.customLabelPlural.toLowerCase();
	}
	get maskProfiles(): boolean {
		return this.state.maskProfiles;
	}
	get showBirthday(): boolean {
		return this.state.showBirthday;
	}
	get showAge(): boolean {
		return this.state.showAge;
	}
	get showPublicBio(): boolean {
		return this.state.showPublicBio;
	}
	get showLastName(): boolean {
		return this.state.showLastName;
	}
	get showFullySponsored(): boolean {
		return this.state.showFullySponsored;
	}
	get currentSponsoredTypePlans(): PartnerSponsoredTypePlansInterface | undefined {
		return this.state.currentSponsoredTypePlans;
	}
	get gridLayout(): string {
		return this.state.gridLayout;
	}
	get identifier(): string {
		return this.state.identifier;
	}
	get sponsoredTypesId(): number {
		return this.state.sponsoredTypesId;
	}
	get customColumns(): CustomColumnsInterface[] | undefined {
		return this.state.customColumns;
	}
	get active(): boolean {
		return this.state.active;
	}
	get titleDescription(): string {
		if(this.state.sponsoredTypesId === SponsoredTypeIds.Animal) {
			return "Species/Breed";
		}
		return "Title";
	}

	getId(): number {
		return this.id ?? 0;
	}

	getSingularLabel(): string {
		return this.customLabelSingular;
	}

	getPluralLabel(): string {
		return this.customLabelPlural;
	}

	getPathLabel(): string {
		return this.identifier;
	}

	getMonthlyAmount(): number {
		if (!!!this.state.currentSponsoredTypePlans) throw new Error("Current Sponsored Plan not defined");
		return this.state.currentSponsoredTypePlans?.monthlyAmount ?? 0;
	}

	getPaymentIntervals(): PaymentIntervalsInterface[] {
		if (!!!this.state.currentSponsoredTypePlans) throw new Error("Current Sponsored Plan not defined");
		return this.state.currentSponsoredTypePlans.partnerSponsoredTypePlanIntervals
			.map(ptp => ptp.paymentIntervals)
			.filter(pi => pi.multiplier !== 0); //remove manual interval;
	}

	getSponsoredTypePlanIntervals(): PartnerSponsoredTypePlanIntervalsInterface[] {
		if (!!!this.state.currentSponsoredTypePlans) throw new Error("Current Sponsored Plan not defined");
		return this.state.currentSponsoredTypePlans.partnerSponsoredTypePlanIntervals;
	}

	getDisplayName(): string {
		return this.getPluralLabel();
	}

	getBlurb(): string {
		return this.state["description"];
	}

	getIcon(): string {
		return this.state.sponsoredTypes?.icon ?? "";
	}

	getTypeDescription(): string {
		return this.state.sponsoredTypes?.description ?? "";
	}

	getMaxSlots(): number {
		return this.state["maxSlots"];
	}

	getSlotLabel(): string {
		return this.customSlotName ?? "Slot";
	}

	getIndexColumnsForType(): Array<object> {
		return indexColumns[this.sponsoredTypesId];
	}

	isMasked(): boolean {
		return this.state["maskProfiles"];
	}

	isSchoolRelated(): boolean {
		return [SponsoredTypeIds.Student, SponsoredTypeIds.Teacher, SponsoredTypeIds.Classroom].includes(this.sponsoredTypesId);
	}

	isIndividual(): boolean {
		return [SponsoredTypeIds.Student, SponsoredTypeIds.Teacher, SponsoredTypeIds.Employee, SponsoredTypeIds.Animal].includes(this.sponsoredTypesId);
	}

	isAnimal(): boolean {
		return this.sponsoredTypesId === SponsoredTypeIds.Animal;
	}

	hasTitle(): boolean {
		return [SponsoredTypeIds.Employee, SponsoredTypeIds.Animal, SponsoredTypeIds.People, SponsoredTypeIds.Project].includes(this.sponsoredTypesId);
	}

	isStaff(): boolean {
		return this.sponsoredTypesId === SponsoredTypeIds.Employee;
	}

	hasCustomColumns(): boolean {
		return !!this.state.customColumns && this.state.customColumns?.length > 0;
	}
}
